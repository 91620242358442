import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Paging from "../components/paging"

export default function RemarkQalist({ data, pageContext }) {
  const { currentPage, numPages, category } = pageContext
  const posts = data.allMdx.nodes
  const linkUrl = `/${category}`
  return (
    <Layout slug="/qa/" category={category} wrapperAddClassName="u-bgc-gray" {...data.meta.nodes[0]}>
      <div className="c-page-title">
        <span>基礎知識</span>
        <h1>歯科衛生士のQ&amp;A</h1>
      </div>

      <div className="p-qa-items">
        {posts.map((node) => (
        <div className="c-qa-item">
          <Link to={node.fields.slug}>
            <h2>{node.frontmatter.title}</h2>
          </Link>
        </div>
        ))}

        <Paging path={linkUrl} currentPage={currentPage} numPages={numPages} />
      </div>
    </Layout>
  )
}

export const query = graphql`
query($category: String!, $skip: Int!, $limit: Int!) {
  meta: allMeta(filter: {slug: {eq: "/qa/"}}) {
    nodes {
      title
      description
      keywords
    }
  }
  allMdx(
    sort: {fields: [frontmatter___date, fields___slug], order: [DESC, ASC]}
    filter: {fields: {category: {eq: $category}}}
    limit: $limit
    skip: $skip
  ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          date
          title
          tags
          description
          organization
          position
          interviewee
        }
      }
    }
  }
`