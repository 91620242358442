import React from "react"
import { Link } from "gatsby"

const createPath = (path, page) => page <= 0 ? '#' : page === 1 ? `${path}/` : `${path}/${page}`

const pageNumber = ({path, currentPage, numPages}) => {
  let start = 1, end = numPages
  const ret = []
  const numberCount = 5
  const numberDiff = numberCount - 1
  const range = numberDiff / 2
  const diffFirst = currentPage - range
  const diffLast = numPages - currentPage

  if (numPages > numberCount) {
    if (range <= diffFirst && range <= diffLast) {
      start = currentPage - range
      end = currentPage + range
    } else if (range > diffFirst) {
      start = diffFirst > 0 ? currentPage - range : 1
      end = start + numberDiff
    } else if (range > diffLast) {
      start = end - numberDiff
    }
  }
  for (let i=start; i<=end; i++) {
    ret.push(
    <li>
      <Link to={createPath(path, i)} className={`${(i === currentPage ? "is-current" : "")}`}>
        {i}
      </Link>
    </li>
    )
  }
  return ret
}


const Paging = ({ currentPage, numPages, path }) => {
  if (numPages < 1) return false
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1
  const nextPage = currentPage + 1

  return (
    <div className="c-pagination">
      <ul>
        <li>
          <Link to={createPath(path, prevPage)} rel="prev" className={`prev${(isFirst ? " is-disabled" : "")}`}>
          前へ
          </Link>
        </li>
        {pageNumber({path, currentPage, numPages})}
        <li>
          <Link to={createPath(path, nextPage)} rel="next" className={`next${(isLast ? " is-disabled" : "")}`}>
            次へ
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default Paging